import React from 'react';
import { graphql, Link } from 'gatsby';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import StandardPageContainer from '../components/layout/StandardPageContainer';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Main from '../static-components/html/Main';
import FormattedDate from '../components/text/FormattedDate';
import BackButton from '../static-components/nav/BackButton';

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

export default function Blog({data, pageContext}){

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        console.log(node);
        const imageID = node.data.target.sys.id;
        const {
           file: {url}, 
           title
        } = data.contentfulBlog.blog.references.find(({contentful_id: id}) => id === imageID);
        return <img src={url} alt={title} />
      },
    },
  }
  return (
    <Main title={data.contentfulBlog.title}>
      <StandardPageContainer title={data.contentfulBlog.title} backButtonComponent={<BackButton path={pageContext.back > 1 ? `/blog/${pageContext.back}` : `/blog`}/>} >
        <Container>
            <Row>
                <Col className="py-3">
                    <Image className="image-cover" src={data.contentfulBlog.image.file.url} style={{maxHeight: "300px"}}/>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className="py-1">
                    <span className="text-strong body-text">{data.contentfulBlog.author}</span><br/>
                    <span className="text-muted"><FormattedDate>{data.contentfulBlog.date}</FormattedDate></span>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    {renderRichText(data.contentfulBlog.blog, options)}
                </Col>
            </Row>
            <Row className="mb-5">
                <Col className='d-flex justify-content-start'>{pageContext.prev && <Link to={"/blog/" + pageContext.prev.url}>Next: {pageContext.prev.title}</Link>}</Col>
                <Col className='d-flex justify-content-end'>{pageContext.next && <Link to={"/blog/" + pageContext.next.url}>Previous: {pageContext.next.title}</Link>}</Col>
            </Row>
        </Container>
      </StandardPageContainer>
    </Main>
  );
}

export const blogQuery = graphql`
  query pageQuery($id: String) {
    contentfulBlog(id: {eq: $id}) {
      url
      author
      title
      subtitle
      date
      image {
        file {
          url
        }
      }
      blog {
        raw
        references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              title
              file {
                  url
              }
            }
        }
      }
    }
  }
`;