import React from "react";
import { navigate } from "gatsby";
import { Container, Button } from "react-bootstrap";
import { ChevronLeft } from "react-bootstrap-icons";

export default function BackButton({path = null, text = null}){

    return(
        <Container className="mb-3 pl-0">
            <Button onClick={() => path ? navigate(path) : navigate(-1)} variant="link"><ChevronLeft/> {text ? text: "Back"}</Button>
        </Container>
    );
}